.slider{
    flex: 1;
    padding-left: 30px;
    height: 400px;
    overflow: hidden;
    color: #fff;
}

.sButton{
    background-color: transparent;
    border: 0;
    width: 40px;
    cursor: pointer;
}

.slideWarper{
    height: 400px;
    display: flex;
    align-items: center;
}
.slideItem{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 ;
}
.slideWho{
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #A8C256;
    opacity: 0;

}
.slideWhoInactive{
    transition-delay: 10s;
    transition: all ease 15s;

}
.slideWhoactive{
    opacity: 1;
    transition: all ease-in 500ms;
}

.slideWhat{
    max-width: 50%;
    color:#fff;
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    line-height: 19.6px;
    font-size: 14px;
    padding-left: 10px;
}

.slideName{
    font-weight: 600;
}


.slideLocl{
    font-weight: 600;
    font-size: 14px;
}

@media screen and (max-width: 1000px){
    .slider{
        flex: none;
        height: 310px;
        width: 600px;
        flex: none;
    }
    .slideWarper{
        width: 100%;
        height: 310px;
        display: flex;
        align-items: center;
    }
    .slideItem{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 0 25px;
    }

    .slideWho{
        width: 100%;
        margin-bottom: 15px;
    }
    .slideWhat{
        max-width: none;
        width: 100%;
        padding: 0;
    }
}

@media screen and (max-width: 850px){
    .slider{
        width: 100%;
        flex: none;
        padding: 0;
    }
    
 }

 @media screen and (max-width: 425px){
    .slider, .slideWarper{
        height: 430px;
    }
    
 }

 
 @media screen and (max-width: 350px){
    .slider, .slideWarper{
        height: 550px;
    }
    
 }