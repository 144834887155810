.cardItem{
    background-color: #A8C256;
    padding: 15px;
    font-family: 'Usual', sans-serif;
    color: #3B5272;
    width: 100%;
    height: 320px;
    position: relative;
}

.cardimage{
    margin-bottom: 15px;
    width: 100%;
}

.cardTitle{
    font-weight: 600;
   margin-bottom: 18px;
   font-size: 17px;
}

.carddescr{
    font-weight: 300;
    font-size: 13px;
    margin-bottom: 15px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.carddescr pre{
    overflow-x: auto;
  

}



.cardurl a{
    font-weight: 600;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
}

.cardurl a:hover{
    text-decoration: underline;
}

