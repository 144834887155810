.newsarea{
    margin: 30px auto;
    width: 900px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap:10px;
    background-color:#159A9C ;
    padding: 30px;
    overflow-y: auto;
    overflow-x: hidden;
    border: #159A9C 3px solid;
}



@media screen and (max-width: 1440px){
    .newsarea{
        width: 80%;
        padding: 10px;
        grid-template-columns:100%;
    }
   
    
  }
