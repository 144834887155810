.formArea{
    width: 900px;
    display: flex;
    flex-direction: column;
    background-color:#159A9C ;
    padding: 30px;
    color: #fff;
    font-family: 'Usual', sans-serif;
}

.youName{
    font-family: 'Usual', sans-serif;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.formArea input{
    display: block;
}

.formArea input::file-selector-button, .formArea input[type=submit]{
    background-color:#002333 ;
    font-family: 'Usual', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    border: 0;
    padding: 10px;
    text-transform: uppercase;
}

.formArea input[type=submit]{
    width: 100%;
}

.formArea input[type=file]{
    margin-bottom: 15px;
    font-size: 16px;
    color: #fff;
}
.formArea input::file-selector-button:hover, .formArea input[type=submit]:hover{
    background-color:#ff9100 ;
}

@media screen and (max-width: 1440px){
    .formArea{
        width: 80%;
    }

    .formArea input{
        width: 100%;
    }
    .formArea input::file-selector-button{
        background-color:#002333 ;
        font-family: 'Usual', sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: #FFFFFF;
        border: 0;
        padding: 10px;
        text-transform: uppercase;
    }
    
  }

