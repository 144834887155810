
.sideBarContainer{
    width: 300px;
    font-family: 'Usual', sans-serif;
    font-size: 16px;
    color: #fff;
}

.sideBarDiv{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    background-color:#159A9C ;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.sideBarLink{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    text-decoration: none;
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
}


.sideBarLink:hover{
    background-color:#159A9C ;
}

.sedeBarLogo{
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sideBarLogOut{
    height: 50px;
    width: 100%;
}

.sideBarLogOut button{
    display: flex;
    width: 100%;
    height: 100%;
    border: 0;
    color: #fff;
    background-color:#159A9C ;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
}

.sideBarLogOut button:hover{
    background-color:  rgb(255, 145, 0) ;
}

.sideBarMob{
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: center;
    border-bottom: #fff 3px solid;
}
.menuSB button{
    background-color: transparent;
    border: 0;
}
.SBMobile{
    position: absolute;
    background-color: #002333;
    width: 100%;
    z-index: 99;
    transition: all ease 1s;
    display: none;
    font-family: 'Usual', sans-serif;
    font-size: 16px;
}


@media screen and (max-width: 850px){
    .sideBarContainer{
        display: none;
    }

    .sideBarMob{
        display: flex;
    }
    .sedeBarLogo{
        width: 100%;
    }
    .SBMobile{
        display: block;
    }
  
}