.unContainer{
    background-color: #2f2e2e;
    color: #fff;
    font-family: 'Usual', sans-serif;
    font-weight: 800;
    font-size: 20px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unContent{
    width: 320px;
}
.unLogo{
    width: 100;
    margin-bottom: 15px;
}
.unInfo{
    display: flex;
    width: 100%;
}

.speech {
    background: #a8c256;
    padding: 30px;
    text-align: center;
    font-size: 25px;
    border-radius: 35px;
    color: #fff;
    position: relative;
}


.speech:before {
    content: "";
    position: absolute;
    border-left: 20px solid #a8c256;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #a8c256;
    border-top: 20px solid transparent;
    right: -20px;
    bottom: 10px;
}

.unText{
    flex: 1;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.unIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.unLink{
    color: #a8c256;
    font-family: 'Usual', sans-serif;
    font-weight: 800;
    font-size: 20px;
    text-decoration: none;
}

.unLink:hover{
    text-decoration: underline;
}