.fill{
    background-color:#002333 ;
    font-family: 'Usual', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    border: 0;
    padding: 10px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.fill:hover{
    background-color:#ff9100 ;
}

.form-foto{
    margin-bottom: 20px;
}

.label3{
    font-family: 'Usual', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.label3 span{
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #e4e4e4;
}

