.team{
    width: 100%;
    background-color: #fff;
}

.teamContainer{
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 45px;
}

.teamTitle{
    font-family: 'Usual', sans-serif;
    font-weight: 600;
    font-size: 56px;
    color: #A8C256;
    line-height: 78.4px;
    padding: 45px 0;
}
.temImage{
    margin-right: 80px;
}
.teamItem{
    display: flex;
    padding: 50px 0;
    border-top: 1px solid #3B5272 ; 
    border-bottom: 1px solid #3B5272 ; 
}

.teamItem:last-child{
    border-top: 0;
}

.temImage img{
    display: block;
    border-radius: 50%;
}

.teamInfo{
    display: flex;
    flex-direction: column;
}

.teamNameContent{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.teamName{
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    font-size: 30px;
    color: #A8C256;
    margin-right: 30px;
}


.teamRole{
    color: #3B5272;
    font-family: 'Usual', sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
}

.temHistory{
    line-height: 22.4px;
    color: #3B5272;
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    font-size: 18px;
}

@media screen and (max-width: 1024px){
    .teamContainer{
        width: 100%;
        padding: 0 30px 45px 30px;
    }
}


@media screen and (max-width: 450px){
    .temImage{
        align-self: center;
        margin: 0 0 30px 0;
    }
    .teamItem{
        flex-direction: column;
        
    }
}