.PP{
    font-family: 'Poppins', sans-serif;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 50px auto;
}

.back{
    position: fixed;
    top: 30px;
    left: 30px;
    width: 60px;
    height: 60px;
    z-index: 99999;
    border-radius: 50%;
}

.back a svg{
   fill: #3b5272 ;
}

.back a:hover svg{
   fill: #a8c256 ;
}


.PPtitulo{
    text-align: center;
    color:#3b5272;
    padding: 15px 0;
    font-size: 35px;
    font-weight: 700;
    margin: 25px;
}

.PPtitulo3{
    text-align: center;
    color:#3b5272;
    padding: 15px 0;
    font-size: 20px;
    font-weight: 700;
    margin: 25px;
}

.PPtexto{
    color:#3b5272;
    font-size: 15px;

}

.PPtitulo1{
    text-align: left;
    color:#3b5272;
    padding: 15px 0;
    font-size: 20px;
    font-weight: 700;
    margin: 25px 0;
}

.PPtexto a{
     color:#3b5272;
}

.PPtexto a:hover{
     color:#a8c256;
}
 
.PPtexto ol{
    list-style-position: inside;
}

.pplinha{
    display: flex;
    border: 1px solid #3b5272;
     color:#3b5272;
    font-size: 15px;
    margin: 10px 0;
}

.cxEsquerda{
    border-right: 1px solid #3b5272;
}
.cxEsquerda, .cxDireita{
    flex:1;
    padding: 10px;
}

.pplinha1{
    display: flex;
    border: 1px solid #3b5272;
     color:#3b5272;
    font-size: 15px;
    margin-top: 5px;
}

.cxEsquerda1{
    border-right: 1px solid #3b5272;
    flex: 1;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.cxDireita1{
    flex:2;
    padding: 10px;
}
