.locontainer{
    min-width: 100vw;
    min-height: 100vh;
    background-color: #002333;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loform{
    max-width: 320px;
}

.loimage{
    width: 320px;
    margin-bottom: 20px;
}

.loform input{
    display: block;
    width: 100%;
    margin-bottom: 15px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    padding:10px;
    border: 0;
}

.loform button{
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    color: #002333;
    border: 0;
    background-color:#B4BEC9 ;
    text-transform: uppercase;
    padding: 10px;
}

.loform button:hover{
    background-color: #DEEFE7;
}

.loError{
    color: rgb(255, 145, 0);
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 10px;
}