*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


html{
  scroll-behavior: smooth;
}


.sisContainer{
  width: 100%;
  min-height: 100vh;
  background-color: #002333;
  display: flex;
}


.contentArea{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}


@media screen and (max-width: 1200px){
  .contentArea{
      padding: 0 20px;
  }
 
  
}

@media screen and (max-width: 850px){
.sisContainer{
  flex-direction: column;
}
}