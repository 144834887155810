.industries{
    width: 100%;
    background-color: #fff;
}

.industriesContainer{
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 45px;
}



.inndustriesTitle{
    font-family: 'Usual', sans-serif;
    font-weight: 600;
    font-size: 56px;
    color: #A8C256;
    line-height: 78.4px;
    padding: 45px 0;
}

.industriesThumbs{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 45px; 
}

.thumbItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #3B5272 ;
    padding: 20px;
}

.thumbImage{
    margin-bottom: 15px;
}

.thumbName{
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #3B5272;
    text-align: center;
}

@media screen and (max-width: 1024px){
    .industriesContainer{
        width: 100%;
        padding: 0 30px 45px 30px;
    }
}


@media screen and (max-width: 850px){
    .industriesThumbs{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows:auto;
        grid-row-gap: 20px; 
    }
}


@media screen and (max-width: 600px){
    .industriesThumbs{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 360px){
    .industriesThumbs{
        grid-template-columns: repeat(1, 1fr);
    }
}