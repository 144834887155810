.floatTop{
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    z-index: 99999;
    border-radius: 50%;
    overflow: hidden;
}




@media screen and (max-width: 1024px){
    .floatTop{
        width: 50px;
    }
}