.hero{
    width: 100%;
    background-image: url('../../image/globe.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 70% 50%;
    border-bottom: 3px solid #fff;
    position: relative;
}

.hero::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 6, 68, .3);
    z-index: -110;
   
}



.heroContainer{
    width: 1000px;
    margin: 0 auto;
    padding: 100px 0 200px 0;
    z-index: 25;
}

.heroContent1{
    display: flex;
}

.heroText{
    font-family: 'Poppins', sans-serif;
    font-size: 51px;
    font-weight: 500;
    line-height: 61.2px;
    max-width: 378px;
    color:#fff;
}
.heroContent2{
    display: flex;
    margin-top: 80px;
    justify-content: space-between;
}

.numbersItem{
    text-align: center;
}
.numberNumber{
    color:#fff;
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    line-height: 65px;
    font-size: 50px;
    font-style: normal;
}

.numberText{
    color:#A8C256;
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    line-height: 31.2px;
    font-size: 24px;
    font-style: normal;
}


@media screen and (max-width: 1024px){
    .heroContainer{
        width: 100%;
        padding: 100px 30px 100px 30px;
    }

}


@media screen and (max-width: 850px){
   .heroContent1, .heroContent2{
    flex-direction: column;
    align-items: center;

   }
   .numbersItem{
    margin-bottom: 30px;
   }
   .heroContent2{
    margin-top: 0;
   }

.heroText{
    width: 100%;
    max-width: 100%;
} 

}

@media screen and (max-width: 600px){
    .hero{
        background-position: 50% 50%;
    }
    .heroText{
        text-align: center;
        width: 100%;
        max-width: 100%;
    } 
}

@media screen and (max-width: 450px){
    .hero {
        background-position: 60% 50%;
    }
    
}

