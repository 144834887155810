.listItem{
    font-family: 'Usual', sans-serif;
    background-color:#002333;
    padding: 10px;
    display: flex;
   max-width: 100%;
}

.listimage{
   width: 120px;
    margin-right: 15px;
}

.listinfo{
    flex: 1;
    display: flex;
    flex-direction: column;
    color:#fff;
}

.listTitle{
    font-size: 16px;
    font-weight: 600;

}
.listdescr, .listTitle{
    margin-bottom: 10px;
}

.listdescr, .divlisturl{
    font-size: 12px;
    font-weight: 300;
}

.listDelete{
    width: 150px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.listDelete button, .listDelete a{
    display: block;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 800;
    padding: 10px;
    border: 0;
    width: 100%;
}
.listDelete button{
    background-color:#ff9100;

}

.listDelete a{
    background-color: #159A9C;
    margin-bottom: 10px;
    text-decoration: none;
    text-align: center;
}
.listDelete button:hover, .listDelete a:hover{
    background-color: #DEEFE7;
    color:#000;
}
.nonem{
    display: none;
}

@media screen and (max-width: 1440px){
    .listItem{
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        overflow: hidden;
    }

    .listimage{
        width: 70%;
         margin-right: 0;
         margin-bottom: 15px;
     }
     .listinfo{
        flex: none;
        width: 100%;
     }
   
    
  }
