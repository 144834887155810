.news{
    width: 100%;
    background-color: #fff;
}

.newsContainer{
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 45px;
}

.nButton{
    width: 50px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}


.newsTitle{
    font-family: 'Usual', sans-serif;
    font-weight: 600;
    font-size: 56px;
    color: #A8C256;
    line-height: 78.4px;
    padding: 0 0 45px 0;
}

.newsThumbs{
   width: 100%;
}


button.rec-dot_active, button.rec-dot:hover, button.rec-dot:active, button.rec-dot_active:focus{
    background-color: rgb(168,194,86);
    box-shadow: 0 0 1px 3px rgba(168,194,86, 0.5);
  }

.button.rec-dot {
    outline: none;
    box-shadow: inset 0 0 1px 1px rgb(168,194,86);
}
  


@media screen and (max-width: 1024px){
    .newsContainer{
        width: 100%;
        padding: 0 30px 45px 30px;
    }
   
    
}

