.services{
    background-color: #3B5272;
    width: 100%;
}

.servicesContainer{
    width: 1000px;
    margin: 0 auto;
}

.sevicesTitle{
    font-family: 'Usual', sans-serif;
    font-weight: 600;
    font-size: 56px;
    color: #fff;
    line-height: 78.4px;
    padding: 45px 0;
}

.servicesContent{
    display: flex;
    padding: 0 0 45px 0;
}

.servicesDesc{
    flex: 1;
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    font-size: 24px;
    color: #fff;
    line-height: 33.6px;
    max-width: 55%;
    padding-right: 40px;
}

.servicesList{
    flex: 1;
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #A8C256;
    line-height: 33.6px;
    max-width: 50%;
    padding-left: 60px;
    display: flex;
}

.servicesList ul{
    list-style: none;
}

@media screen and (max-width: 1024px){
    .servicesContainer{
        width: 100%;
        padding: 0 30px;
    }
    .servicesList{
        justify-content: center;
    }
}

@media screen and (max-width: 850px){
    .servicesContent{
        flex-direction: column;
    }
    .servicesList{
        justify-content: left;
        width: 100%;
        padding: 0;
        width: 100%;
        max-width: none;
    }
    .servicesDesc{
        max-width: none;
        width: 100%;
        margin-bottom: 35px;
    }
}
