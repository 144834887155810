.erro{
    color:#ff9100
}
.popUp{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    z-index: 99999;
    color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label2{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 20px;
}