.formArea2{
    width: 900px;
    display: flex;
    flex-direction: column;
    background-color:#159A9C ;
    padding: 30px;
    color: #fff;
    font-family: 'Usual', sans-serif;
    margin-top: 30px;
}

.newsForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 300;
}
.newsForm input, textarea{
    display: block;
    margin-bottom: 15px;
    border: 0;
    color: #000;
    font-size: 14px;
    padding: 5px 0;
}

.newsForm input[type=submit]{
    color:#fff;
    font-weight: 800;
    padding: 10px;
    background-color: #002333;
}

.newsForm input[type=submit]:hover{
    background-color:#ff9100 ;
}

@media screen and (max-width: 1440px){
    .formArea2{
        width: 80%;
    }
   
    
  }
