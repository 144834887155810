.values{
    width: 100%;
    background-color: #A8C256;
    border-bottom: 3px solid #3B5272;
}
.valueContainer{
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 45px;
}
.valueTitle{
    font-family: 'Usual', sans-serif;
    font-weight: 600;
    font-size: 56px;
    color: #3B5272;
    line-height: 78.4px;
    padding: 45px 0;
}

.valueItem{
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-bottom: #3B5272 1px solid;
}

.valueItem:last-child{
    border-bottom: 0;
}

.valueItemTitle{
    font-family: 'Usual', sans-serif;
    font-weight: 600;
    font-size: 28px;
    color: #fff;
    width: 250px;
    margin-right: 30px;
}

.valueItemText{
    font-family: 'Usual', sans-serif;
    font-weight: 600;
    font-size: 17px;
    color: #3B5272;
}


@media screen and (max-width: 1024px){
    .valueContainer{
        width: 100%;
        padding: 0 30px 45px 30px;
    }
}

@media screen and (max-width: 850px){
    .valueItem{
        flex-direction: column;
    }
    .valueItemTitle{
        margin-bottom: 15px;
        width: 100%;
        margin-right: 0;
    }
    .valueItemText{
        width: 100%;
    }
}
