.imageArea, .imageArea2{
    margin: 30px auto;
    width: 900px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:10px;
    background-color:#159A9C ;
    padding: 30px;
    overflow-y: auto;
    overflow-x: hidden;
    border: #159A9C 3px solid;
}
.imageArea{
    max-height: 400px;
}

.imageArea2{
    max-height: 100%;
}


.contentTitle, .loading{
    font-family: 'Usual', sans-serif;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 50px;
}

@media screen and (max-width: 1440px){
    .imageArea, .imageArea2{
        width: 80%;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }
}



