.lgpdWarper{
    position: fixed;
    bottom: 20px;
    left: -200%;
    z-index: 55;
    width: 45%;
    height: fit-content;
    background-color: #a8c256;
    border: 2px solid #3b5272;
    padding: 25px;
    color:#fff;
    border-radius: 10px;
    display: flex;
    transition: all ease 1s;
    padding-right: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
}

.active{
    left: 20px;
}
.lgpdEsquerda{
    width: 100%;
}

.texto a{
 color: #fff;
}

.texto a:hover{
 color: #3b5272;
}

.lgpdDireita{
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lgpdDireita button{
    background: none;
    border: 0;
    cursor: pointer;
}

.lgpdDireita button svg{
    fill: #3b5272;
}

@media screen and (max-width: 1440px){
    .lgpdWarper{
        height: fit-content;
    }
}