.container-photo{
    font-family: 'Usual', sans-serif;
    background-color: #002333;
    padding: 10px;
    display: flex;
    width: 100%;
}
.imagem, .infos{
    display: flex;
    flex-direction: column;
}

.imagem img{
    height: auto;
    width: 150px;
}

.infos{
    margin-left: 25px;
}

.name, .url{
    font-size: 12px;
    margin-bottom: 10px;
    color:#fff;
}

.name span, .url span{
    font-weight: bold;
    display: none;
}

.url input {
    color:#fff;
    background-color: transparent;
    outline: none;
    border: none;
    display: block;
    display: none;
}

.url button{
    display: block;
    margin: 15px 0;
    width: 100%;
}

.copy{
    font-family: 'Usual', sans-serif;
    background-color: #159A9C;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
    border: 0;
    display: block;
}
.copy:hover, .delete:hover{
    background-color: #DEEFE7;
    color:#000;
}


.delete{
    font-family: 'Usual', sans-serif;
    background-color: #ff9100;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
    border: 0;
}

@media screen and (max-width: 950px){
    .container-photo{
        flex-direction: column;
        align-items: center;
    }
    .imagem img{
        height: auto;
        width: 100%;
    }
    .url{
        width: 100%;
    }
    .infos{
        margin: 0;
    }
    
  }



