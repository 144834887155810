.footer{
    width: 100%;
    background-color: #141416;
    color: #fff;
}

.footerContainer{
    width: 1000px;
    margin: 0 auto;
    padding: 45px 0;
    display: flex;
}

.footer1, .footer2, .footer3{
    flex: 1;
    font-family: 'Usual', sans-serif;
}
.footerTitle{
    font-weight: 600;
    font-size: 14px;
    color: #A8C256;
    margin-bottom: 5px;
}

.footerAdress{
    font-weight: 300;
    font-size: 14px;
    color: #fff;
    line-height: 19.6px;
}

.footerAdress span{
    font-weight: 600;
}

.footerItem{
    margin-bottom: 20px;
}

@media screen and (max-width: 1024px){
    .footer{
        padding-bottom: 50px;
    }
    .footerContainer{
        width: 100%;
        padding:45px 30px;
    }
}


@media screen and (max-width: 850px){
    .footerContainer{
        flex-direction: column;
    }
}

