header{
    width: 100%;
    border-bottom: 3px solid #fff;
    background-color: #2F2E2E;
}

.headerContainer{
    width: 1000px;
    height: 95px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headerLogo{
    width: 250px;
}

.headerLogo img{
    width: 100%;
}
.headerNav ul{
    display: flex;
    list-style: none;
    overflow: hidden;
}
.navDesk{
    color: #fff;
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    font-size: 15px;
    margin-right: 25px;
    text-decoration: none;  
    padding: 10px;
    position: relative;
    z-index: 0;
    display: block;
    overflow: hidden;
}

.navDesk:last-child{
    margin-right: 0;
}

.navDesk::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    background-color: #fff;
    width: 100%;
    height: 1px;
    z-index: -1;
    transition: all ease 300ms ;
}

.navDesk:hover::before{
    left: 0;
}

.menuHamburguer, .menuMobile{
    display: none;
}

.menuHamburguer button{
    background-color: transparent;
    border: 0;
}

.menuMobile{
    position: absolute;
    background-color: #2F2E2E;
    width: 100%;
    z-index: 99;
    transition: all ease 1s;
}

.menuMobile ul{
    list-style: none;
}
.menuMobile ul li{
    display: flex;
    padding: 15px 0;
    justify-content: center;
    align-items: center;
}

.menuMobile ul li a{
    color: #fff;
    font-family: 'Usual', sans-serif;
    font-weight: 300;
    font-size: 18px;
    text-decoration: none;
}

@media screen and (max-width: 1024px){
    .headerContainer{
        width: 100%;
        padding: 0 30px;
    }
}

@media screen and (max-width: 850px){
    .headerContainer{
        flex-direction: column;
        justify-content: center;
        height:min-content;
        padding: 30px 0;
    }
    .headerLogo{
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 768px){
   
    nav{display: none;}
    .menuHamburguer, .menuMobile{
        display: block;
    }
}



