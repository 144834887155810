.presence{
    width: 100%;
    background-color: #fff;
}

.preContainer{
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 45px;
    font-family: 'Usual', sans-serif;
}

.preTitle{
    font-family: 'Usual', sans-serif;
    font-weight: 600;
    font-size: 56px;
    color: #3B5272;
    line-height: 78.4px;
    padding: 45px 0;
}

.preButtonArea{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px; 
    margin-bottom: 30px;
}

.preButton{
    background-color: #A8C256;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    border-radius: 0;
    border: 0 ;
    padding: 10px 0;
}
.preButton:hover{
    background-color: #3B5272;
}

.activeButton{
    background-color: #3B5272;
}
.preItemArea{
    
    border: 1px #3B5272 solid;
}
.preItem{
    position: relative;
    top: 0;
    left: 0;
    display: none;
    max-height: 600px;
}

.preItemActive{
    display: flex;
}

.preItemMap{
    width: 350px;
    border-right: 1px #3B5272 solid;
}

.preItemInfo{
    flex: 1;
    padding: 50px;
    font-family: 'Usual', sans-serif;
}

.preItemTitle{
    color: #3B5272;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}
.preItemText{
    color: #3B5272;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
}

@media screen and (max-width: 1024px){
    .preContainer{
        width: 100%;
        padding: 0 30px 45px 30px;
    }
}

@media screen and (max-width: 680px){
    .preButtonArea{
        grid-template-columns: repeat(4, 1fr);
    }

    .preItemActive{
        flex-direction: column;
    }

    .preItem{
        max-height: none;
    }
    .preItemMap{
        width: 100%;
        border-bottom: 1px #3B5272 solid;
        border-right: 0;
    }
    .preItemArea{
        max-width: 320px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 500px){
    .preButtonArea{
        grid-template-columns: repeat(3, 1fr);
    }
    .preItemInfo{
        padding: 20px;
    }

}

@media screen and (max-width: 400px){
    .preButtonArea{
        grid-template-columns: repeat(2, 1fr);
    }
   

}